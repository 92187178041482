import React from 'react';
import styled from 'styled-components';
import SEO from 'components/seo';
import { GoBackButton, MobileHeading } from 'components/misc';
import MediaLayout from 'layouts/media-layout';

const BookOfMark = ()=> {
  return (
    <MediaLayout>
      <SEO title="Media" />
      <GoBackButton path="media" name="Media" />
      <MobileHeading heading="Prezentacje" />
      Księga znaku
    </MediaLayout>
  );
};

export default BookOfMark;
